import React from 'react'

import PropTypes from 'prop-types'

import './holo-card.css'

const HoloCard = (props) => {
  return (
    <div className={`holo-card-feature-card ${props.rootClassName} `}>
      <div className="holo-card-gallery-card">
        <img alt="image" src={props.image_src} className="holo-card-image" />
        <div className="holo-card-legend">
          <h1 className="holo-card-type-card">{props.TypeCard}</h1>
        </div>
        <div className="holo-card-container">
          <h1 className="holo-card-heading">{props.Heading}</h1>
          <span className="holo-card-text">{props.text}</span>
        </div>
      </div>
    </div>
  )
}

HoloCard.defaultProps = {
  rootClassName: '',
  Heading: 'Project Title',
  text: 'Lorem ipsrtum dolor sit amet, cortrnsectetur adipiscing elit',
  TypeCard: 'Type',
  image_src: '/assets/holokd_base-300w.png',
}

HoloCard.propTypes = {
  rootClassName: PropTypes.string,
  Heading: PropTypes.string,
  text: PropTypes.string,
  TypeCard: PropTypes.string,
  image_src: PropTypes.string,
}

export default HoloCard
