import React from "react";
import c from "./ShowBlog.module.css";
import ToText from "../../utils/ToText";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";

function ShowBlog(props, p) {
 
  return (
    <div className={c.blog_post_card}>
      <div className={c.blog_container}>
        <div
          className={c.blog_image}
          style={{ backgroundImage: `url(${props.thumbnail})` }}
        >
        </div>

        <div className={c.blog_container}>
          <h5 className={c.blog_text1}>
            
            <Link
              to={props.title}
              className={c.textfiordblue}

            >
              {props.title}
            </Link>
          </h5>

          <p className={c.blog_text2}>{`${ToText(
            props.description.substring(0, 500)
          )}...`}</p>
          <br />
          <span className={c.blog_text3}>
            {props.author}
          </span>
          <br />
          <span className={c.blog_text4}>
            {moment(props.pubDate).format("DD MMM, YYYY hh:mm")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ShowBlog);
