import React from 'react';
import './Footer.css';
import { Translator } from './I18n';

function Formevent() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <h1 className='footer-subscription-heading'>
        <Translator path="messages.form_event1"/>
        </h1>
        <p className='footer-subscription-text'>
        <Translator path="messages.form_event2"/>
        </p>
        <p className='footer-subscription-text'>
        <Translator path="messages.newsletter3"/>
        </p>
        <div className='input-areas'>
            <iframe title="mautic2" src="//mautic.holokd.com/form/4" width="420" height="800"><p>Your browser does not support iframes.</p></iframe>
        </div>
      </section>
    </div>
  );
}

export default Formevent;
