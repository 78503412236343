// eslint-disable-next-line import/no-anonymous-default-export
export default {
    translations: {
        titles: {
            app: 'HOLOKD',
            cyberverse: 'O QUE É CYBERVERSE?',
            team: 'EQUIPE',
            blog: 'BLOG',
            adviser: 'CONSELHEIROS',
            thegame: 'Nosso jogo conecta atividades esportivas da vida real com jogos online e recompensas incríveis',
            partners: 'INVESTIDORES, PARCEIROS & COMUNIDADES',
            whatis: 'O QUE SÃO AS HOLOKDS?'
        },
        menu: {
            about: 'SOBRE',
            blog: 'BLOG',
            contact: 'CONTATO',
            instagram: 'SIGA NO INSTAGRAM'
        },
        messages: {
            description: 'VAMOS MOVIMENTAR JUNTOS!',
            smallText_01: 'HoloKD é um jogo para celular que propõe hábitos saudáveis',
            smallText_02: 'divertidos e cria memória afetiva para famílias com crianças e adolescentes.',
            cyber_01: 'HoloKD Warriors é um jogo 3D de mundo aberto. A história tem como pano de fundo o fantástico e tecnológico Cyberverse, um mundo cibernético com diversos ambientes, habitado e governado por seres humanóides robóticos que se organizam em diferentes espaços e cidades.',
            cyber_02: 'Apesar de quererem viver em harmonia, muitas ameaças comprometem a ordem no Ciberverso: a principal ameaça é o temido vilão Z e os Bugs, monstros que possuem os mais diversos poderes.',
            cyber_03: 'Para se defender desses seres, os habitantes do mundo cibernético criaram a Técnica HoloKDs, um método que consistia em utilizar um baralho de cartas holográficas, cujos poderes eram baseados em aparelhos analógicos usados em esportes e jogos infantis do passado.',
            newsletter1: 'Tenha acesso ao game antecipado aqui!',
            newsletter2: 'Benefícios: 01-Acesso para testar.  02-Acesso a comunidade HoloKD.  03-Ganhar bônus e pontos no lançamento.',
            newsletter3: 'Você pode cancelar seu cadastro a qualquer momento.',
            partners_msg: 'Conheça nossos investidores, parceiros e comunidades com o mesmo propósito de impactar possitivamente na vida de milhares de pessoas e você também pode fazer parte, entre em contato com nossa equipe.',
            thegame_msg: 'Escolhas suas HoloKDs ou Caminhe mais para entrar em vários jogos de batalha com seus Guerreiros no HoloKD GYM® Plataforma. A plataforma foi projetada de forma a permitir que os jogadores se beneficiem usando todas as mecânicas do jogo.',
            whatis_msg: 'As HoloKDs são Cards Virtuais colecionáveis inspirador nas brincadeiras vintage e todas modalidades esportivas do universo que evoluem conforme sua utilização e valorização. O usuário pode criar as suas próprias HoloKDs, compartilhar com amigos. Após participarem das atividades a HoloKD será capturada em Realidade Aumentada ou por leitura de sensores embarcados no dispositivo, pontuando os participantes, ganhando itens, moedas e XP. Subindo o rank dos melhores jogadores por regiões.',
            thanks: 'Muito Obrigado!',
            more_info: 'O mais breve possível vamos entrar em contato e enviar maiores informações.',
            follow: 'Siga nossa redes sociais e indique as amigos.',
            form_event1: 'Que evento INCRÍVEL e vamos nos CONECTAR!',
            form_event2: 'Cadastre-se aqui e receba maiores informações.',
            copy_msg1: 'Para acessar o app HoloKD,',
            copy_msg2: 'faça o download na App Store ou no Google Play.',
            copy_msg3: 'Abra o HoloKD e digite o código abaixo.'
        },
        team: {
            ceo: 'CEO',
            art3D: 'ART 3D',
            community: 'COMUNIDADE',
            adviser: 'CONSELHEIROS',
            physical: 'EDUCAÇÃO FÍSICA',
            blockchain: 'NFT E BLOCKCHAIN',
            gameDesign: 'DESIGNER DE JOGOS'
        },
        buttons: {
            save: 'SALVAR',
            discord: 'ENTRE EM NOSSO DISCORD',
            trailer: 'ASSISTA O TRAILER',
            subscribe: 'INSCREVER',
            copycode: 'COPIAR CÓDIGO'
        },
        cards: {
            title01: 'PIPA',
            desc01: 'Poder de voar',
            type01: 'LENDÁRIA',
            title02: 'SKATE',
            desc02: 'Deslizar rapidamente',
            type02: 'LENDÁRIA',
            title03: 'FUTEBOL',
            desc03: 'Correr e chutar forte',
            type03: 'LENDÁRIA',
            title04: 'BOLINHA DE GUDE',
            desc04: 'Bolinha de fumaça, escoregar e imobilizar',
            type04: 'LENDÁRIA'                                        
        },
    }
}
