import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Blog from './components/Blog/Blog';
import Thanks from './components/thanks';
import Privacy from './components/privacy';
import FormEvent from './components/Formevent';
import SingleBlog from "./components/SingleBlog/SingleBlog";
import SignUp from './components/pages/SignUp';
import Whitepaper from './components/pages/Whitepaper';
import Download from './components/Download';
import './i18n';
import './style.css';

import ReactGA from 'react-ga';
  const TRACKING_ID = "G-YFXQCP81CC"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/thanks' component={Thanks} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/formevent' component={FormEvent} />
          <Route path='/blog' component={Blog} />
          <Route path="/:id" exact component={SingleBlog} />
          <Route path='/services' component={Services} />
          <Route path='/whitepaper' component={Whitepaper} />
          <Route path='/sign-up' component={SignUp} />
          <Route path="/download/:code" component={Download} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
