import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Partners from '../partners';
import Game from '../game';
//import Partners from '../Partners';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
//import RoadMap from '../RoadMap';
import Cyberverse from '../cyberverse';

function Home() {
  return (
    <>
      <HeroSection />
      <Cyberverse />
      <Game />
      <Cards />
      <Partners />
      <Footer />
    </>
  );
}

export default Home;
