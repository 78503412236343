import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Translator } from '../components/I18n';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/HoloKD_Site.mp4' autoPlay loop muted />
      <h1><Translator path="titles.app"/></h1>
      <p><Translator path="messages.description"/></p>
      <h2><Translator path="messages.smallText_01"/></h2>
      <h2><Translator path="messages.smallText_02"/></h2>   
      <div className='hero-btns'>
        <a href='https://discord.gg/NuxFKPsyY2' target='_blank'>
          <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'><Translator path="buttons.discord"/></Button>
        </a>
        <a href='https://www.youtube.com/watch?v=wmVl2w41wa0' target='_blank'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
        >
          <Translator path="buttons.trailer"/> <i className='far fa-play-circle' />
        </Button>
        </a>
      </div>
    </div>
  );
}

export default HeroSection;
