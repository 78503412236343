import React, { useEffect} from 'react';
import '../../App.css';

function SignUp() {
    useEffect(() => {
        window.location.href = "https://instagram.com/holokdgame";
      }, []);
  return (
    <div>
    </div>
  );
}

export default SignUp;