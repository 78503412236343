import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import { Translator } from '../components/I18n';

function Cards() {
  return (
    <div className='cards'>
      <h1 className='h1'><Translator path="titles.team" /></h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/AndreCruz.jpg'
              text='André Cruz'
              label='CEO'
              path="https://www.linkedin.com/in/andrercruz/"
            />
            <CardItem
              src='images/WalisonBarreira.jpg'
              text='Walison Barreira'
              label='3D ART'
              path='/'
            />
            <CardItem
              src='images/YanCruz.jpg'
              text='Yan Medeiros'
              label='COMMUNITY'
              path="https://www.linkedin.com/in/yanmcruz/"
            />
          </ul>
        </div>
      </div>
      <h1 className='h1'><Translator path="titles.adviser" /></h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/LiviaMietherhofer.jpg'
              text='Lívia Mietherhofer'
              label='PHYSICAL EDUCATION'
              path='https://www.linkedin.com/in/livia-mietherhofer-9762a6132/'
            />
            <CardItem
              src='images/FelipeCarreiro.jpg'
              text='Felipe Carreiro'
              label='NFT & BLOCKCHAIN'
              path='https://www.linkedin.com/in/carreirofelipe/'
            />
            <CardItem
              src='images/joaofialho.jpg'
              text='João Fialho'
              label='Investor Raja Ventures'
              path='https://www.linkedin.com/in/joaofialho1/'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
