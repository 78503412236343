import React from 'react'

import PropTypes from 'prop-types'

import './partner-card.css'

const PartnerCard = (props) => {
  return (
    <div className={`partner-card-feature-card ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="partner-card-image"
      />
    </div>
  )
}

PartnerCard.defaultProps = {
  image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  image_alt: 'image',
  rootClassName: '',
}

PartnerCard.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default PartnerCard
