import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import I18n, { Translator } from '../components/I18n';
import ReactGA from 'react-ga';
import './Navbar.css';

function Navbar() {
  
  // setup navbar
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src="/assets/Logo150px.png" alt=' ' width="60px" />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                <Translator path="menu.about" />
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/blog' className='nav-links' onClick={closeMobileMenu}>
                <Translator path="menu.blog" />
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/formevent' className='nav-links' onClick={closeMobileMenu}>
                <Translator path="menu.contact" />
              </Link>
            </li>
            <li>
              <Link
                to='/services'    
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                <Translator path="menu.instagram"/>
              </Link>
              {!button && <I18n />}
            </li>

          </ul>
          <Link to='/sign-up' className='btn-mobile'>
          {button && <Button buttonStyle='btn--outline'><Translator path="menu.instagram"/></Button>}
          </Link>
          {button && <I18n />}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
