// eslint-disable-next-line import/no-anonymous-default-export
export default {
    translations: {
        titles: {
            app: 'HOLOKD',
            cyberverse: 'WHAT IS THE CYBERVERSE?',
            team: 'TEAM',
            blog: 'BLOG',
            adviser: 'ADVISER',
            thegame: 'Our game connects real-life sports activities with online gaming and earning incredible benefits',
            partners: 'INVESTORS, PARTNERS & COMMUNITIES',
            whatis: "WHAT IS THE HOLOKDS?"
        },
        menu: {
            about: 'ABOUT',
            blog: 'BLOG',
            contact: 'CONTACT',
            instagram: 'FOLLOW INSTAGRAM'
        },
        messages: {
            description: 'LET´S MOVE TOGETHER!',
            smallText_01: 'HoloKD is a mobile game that proposes healthy habits',
            smallText_02: 'fun and creates an affective memory for families with children and teenagers.',
            cyber_01: 'HoloKD Warriors is a 3D open worldgame. The story is set against the backdrop of the fantastic and technological Cyberverse, a cybernetic world with diverse environments, inhabited and ruled by robotic humanoid beings that organize themselves in different spaces and cities.',
            cyber_02: 'Despite wanting to live in harmony, many threats compromise order in the Cyberverse: the main threat is the feared villain Z and bugs, monsters that have the most diverse powers.',
            cyber_03: 'To defend themselves against these beings, the inhabitants of the cyber world created the HoloKDs Technique, a method that consisted of using a deck of holographic cards, whose powers were based on analog devices used in sports and childrens games of the past.',
            newsletter1: 'Get early access to the game here!',
            newsletter2: 'Benefits: 1-Access to test. 2-Access to the HoloKD community. 3-Earn bonuses and points at launch.',
            newsletter3: 'You can unsubscribe at any time.',
            partners_msg: 'Meet our partners and communities with the same purpose of positively impacting the lives of thousands of people and you too can be a part, get in touch with our team.',
            thegame_msg: 'Choose your HoloKDs or Walk further to enter multiple battle games with your Warriors on the HoloKD GYM® Platform. The platform was designed in a way that allows players to benefit financially using all of the game´s mechanics.',
            whatis_msg: 'HoloKDs are collectible Virtual Cards inspired by vintage games and all sports in the universe that evolve according to their use and appreciation. User can create their own HoloKDs, share with friends. After participating in the activities, HoloKD will be captured in Augmented Reality or by reading sensors embedded in the device, scoring participants, earning items, coins and XP. Ranking up the best players by regions.',
            thanks: 'Thanks a lot!',
            more_info: 'We will contact you as soon as possible and send you more information.',
            follow: 'Follow our social media and tell your friends.',
            form_event1: 'What an AMAZING event and let´s CONNECT!',
            form_event2: 'Register here and receive more information.',
            copy_msg1: 'To join in HoloKD,',
            copy_msg2: 'download on the App Store or Google Play.',
            copy_msg3: 'Open HoloKD, and enter the code below.'
        },
        team: {
            ceo: 'CEO',
            art3D: '3D ART',
            community: 'COMMUNITY',
            adviser: 'ADVISERES',
            physical: 'PHYSICAL EDUCATION',
            blockchain: 'NFT & BLOCKCHAIN',
            gameDesign: 'GAME DESIGNER'
        },
        buttons: {
            save: 'SAVE',
            discord: 'JOIN DISCORD',
            trailer: 'WATCH TRAILER',
            subscribe: 'SUBSCRIBE',
            copycode: 'COPY CODE'
        },
        cards: {
            title01: 'KITE',
            desc01: 'Power to fly',
            type01: 'LENGENDARY',
            title02: 'SKATE',
            desc02: 'Swipe fast',
            type02: 'LENGENDARY',
            title03: 'SOCCER',
            desc03: 'Run and kick hard',
            type03: 'LENGENDARY',
            title04: 'MARBLES',
            desc04: 'Smoke ball, slip and immobilize',
            type04: 'LENGENDARY'            
        },
    }
}

