/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './cyberverse.css';
import img_eugen from '../components/images/eugencards_holokd.png'
import img_filoo from '../components/images/filoocards_holokd.png'
import img_brunn from '../components/images/brunncards_holokd.png'
import img_z from '../components/images/zcards_holokd.png'
import img_bg from '../components/images/holokd_cyberverse_1920x1080.jpg'
import { Translator } from '../components/I18n';

const Cyberverse = (props) => {
  return (
    <div className="cyberverse-container">
      <div id="ourstory" className="cyberverse-our-story">
        <div className="cyberverse-heading-container">
          <h1 className="cyberverse-text section-Heading">CYBERVERSE</h1>
        </div>
        <div className="cyberverse-cards1-container">
          <div className="cyberverse-left-section">
            <a
              href="https://www.youtube.com/watch?v=wmVl2w41wa0"
              target="_blank"
              rel="noreferrer noopener"
              className="cyberverse-link"
            >
              <div className="cyberverse-video-container">
              <img
                src={img_bg}
                className="cyberverse-image"
                alt="image"
              />
                <div className="cyberverse-play-container">
                  <svg viewBox="0 0 1024 1024" className="cyberverse-icon">
                    <path d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM426 704v-384l256 192z"></path>
                  </svg>
                </div>
              </div>
            </a>
            <div className="cyberverse-content-container">
              <span className="cyberverse-heading card-Heading">
              <Translator path="titles.cyberverse" />
              </span>
              <span className="cyberverse-text1 card-Text">
                <span></span>
                <span>
                    <Translator path="messages.cyber_01"/>
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  <Translator path="messages.cyber_02"/>
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  <Translator path="messages.cyber_03"/>
                </span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
          <div className="cyberverse-right-section">
            <div className="cyberverse-card">
              <img
                src={img_eugen}
                className="cyberverse-image"
                alt="image"
              />
            </div>
            <div className="cyberverse-card1">
              <img
                alt="image"
                src={img_filoo}
                className="cyberverse-image1"
              />
            </div>
            <div className="cyberverse-card2">
              <img
                alt="image"
                src={img_brunn}
                className="cyberverse-image2"
              />
            </div>
            <div className="cyberverse-card3">
              <img
                alt="image"
                src={img_z}
                className="cyberverse-image3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cyberverse
