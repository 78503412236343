import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { Translator } from '../components/I18n';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <h1 className='footer-subscription-heading'>
        <Translator path="messages.newsletter1"/>
        </h1>
        <p className='footer-subscription-text'>
        <Translator path="messages.newsletter2"/>
        </p>
        <p className='footer-subscription-text'>
        <Translator path="messages.newsletter3"/>
        </p>
        <div className='input-areas'>
            <iframe title="mautic1" src="//mautic.holokd.com/form/1" width="400" height="300"><p>Your browser does not support iframes.</p></iframe>
        </div>
      </section>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              HoloKD
            </Link>
          </div>
          <small class='website-rights'>All Rights ©HoloKD Games 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to={{ pathname: 'https://www.facebook.com/holoKDgame' }}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to={{ pathname: 'https://instagram.com/holokdgame' }}              
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link discord'
              to={{ pathname: 'https://discord.gg/NuxFKPsyY2' }}                
              target='_blank'
              aria-label='Discord'
            >
              <i class='fab fa-discord' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: 'https://twitter.com/holokdgame' }}                     
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
