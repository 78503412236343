import React, { useEffect} from 'react';
import '../../App.css';
//import { Link } from 'react-router-dom';

function Whitepaper() {
    useEffect(() => {
        window.location.href = "https://holokd.com";
      }, []);
  return (
    <div>
    </div>
  );
}

export default Whitepaper;