import React from 'react'

import HoloCard from '../components/holo-card'
import './game.css'
import { Translator } from '../components/I18n';

var titleCard_1 = <Translator path="cards.title01"/>
var descCard_1 = <Translator path="cards.desc01"/>
var typeCard_1 = <Translator path="cards.type01"/>

var titleCard_2 = <Translator path="cards.title02"/>
var descCard_2 = <Translator path="cards.desc02"/>
var typeCard_2 = <Translator path="cards.type02"/>

var titleCard_3 = <Translator path="cards.title03"/>
var descCard_3 = <Translator path="cards.desc03"/>
var typeCard_3 = <Translator path="cards.type03"/>

var titleCard_4 = <Translator path="cards.title04"/>
var descCard_4 = <Translator path="cards.desc04"/>
var typeCard_4 = <Translator path="cards.type04"/>

const Game = (props) => {
  return (
    <div className="game-container">
      <div className="game-features">
        <h1 className="game-text">
          <span><Translator path="titles.thegame"/></span>
          <br></br>
        </h1>
        <span className="game-text3">
          <Translator path="messages.thegame_msg"/>
        </span>
        <div className="game-container1">
          <HoloCard
            rootClassName="rootClassName"
            text={descCard_1}
            TypeCard={typeCard_1}
            Heading={titleCard_1}
            image_src="/assets/HOLOKD_KITE.png"
            ></HoloCard>
          <HoloCard
            rootClassName="rootClassName3"
            text={descCard_2}
            TypeCard={typeCard_2}
            Heading={titleCard_2}
            image_src="/assets/HOLOKD_skate.png"
          ></HoloCard>
          <HoloCard
            rootClassName="rootClassName5"
            text={descCard_3}
            TypeCard={typeCard_3}
            Heading={titleCard_3}
            image_src="/assets/HOLOKD_Soccer.png"
          ></HoloCard>
          <HoloCard
            rootClassName="rootClassName1"
            text={descCard_4}
            TypeCard={typeCard_4}
            Heading={titleCard_4}
            image_src="/assets/HOLOKD_MARBLE.png"
          ></HoloCard>
        </div>
        <h1 className="game-text">
        <br></br>
          <span><Translator path="titles.whatis"/></span>
          <br></br>
        </h1>
        <span className="game-text3">
          <Translator path="messages.whatis_msg"/>
        </span>
      </div>
    </div>
  )
}

export default Game
