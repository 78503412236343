import React from 'react'

import PartnerCard from '../components/partner-card'
import './partners.css'
import { Translator } from '../components/I18n';

const Partners = (props) => {
  return (
    <div className="partners-container">
      <div className="partners-features">
        <h1 className="partners-text">
          <span><Translator path="titles.partners"/></span>
          <br></br>
        </h1>
        <h2 className="partners-text3">
        <span><Translator path="messages.partners_msg"/></span>
        </h2>
        <div className="partners-container1">
          <PartnerCard
            rootClassName="partner-card-root-class-name"
            image_src="/assets/Logo_Brde.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name1"
            image_src="/assets/Logo_FSA.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name2"
            image_src="/assets/Logo_Ancine.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name3"
            image_src="/assets/Logo_Faperj.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_StartupRio.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_StartupNE.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_FastMotion.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/sebrae_likeaboss-200h.png"
          ></PartnerCard>                    
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_Raja.png"
          ></PartnerCard>          
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_IndieHero.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_GFC.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_RajaValley.png"
          ></PartnerCard>
          <PartnerCard
            rootClassName="partner-card-root-class-name4"
            image_src="/assets/Logo_RSV.png"
          ></PartnerCard>                                                                     
        </div>
      </div>
    </div>
  )
}

export default Partners
